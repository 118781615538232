import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    body{
        font-family: 'acumin-variable','Roboto', sans-serif;
        overflow-x: hidden;
        h1{
            font-size: 3em;
        }
        h1, h2, h3 {
            font-family: 'acumin-pro-condensed', 'Oswald', sans-serif;
            @media (max-width: 768px){
                font-size: 2em;
            }
        }
    }
`;

export default GlobalStyles;
