import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//Pages
import Home from "./pages/home";
import Profile from "./pages/profile";
import ThankYou from "./pages/thankyou";
import Unsubscribe from "./pages/unsubscribe";
import Navigation from "./components/Navigation";
import MyLoyalBlue from "./pages/myloyalblue";
import KidsClub from "./pages/kidsclub";
import WineAuction from "./pages/wineauction";
import SubscribeToParam from "./pages/SubscribeToParam";

import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

const theme = createTheme();

const useStyles = makeStyles((theme) => {
  root: {
    // some CSS that accesses the theme
  }
});

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/profile">
              <Navigation />
              <Profile />
            </Route>
            <Route exact path="/thankyou">
              <Navigation />
              <ThankYou />
            </Route>
            <Route exact path="/unsubscribe">
              <Navigation />
              <Unsubscribe />
            </Route>
            <Route exact path="/subscribe">
              <Navigation />

              <SubscribeToParam />
            </Route>

            {/* Extra Subscription Centers*/}
            <Route exact path="/kids">
              <Home />
            </Route>
            <Route exact path="/kidsclub">
              <KidsClub />
            </Route>
            <Route exact path="/wineauction">
              <WineAuction />
            </Route>
            <Route exact path="/myloyalblue">
              <MyLoyalBlue />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
