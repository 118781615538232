import SubscriptionForm from "../components/SubscriptionForm";
import styled from "styled-components";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const Home = () => {
  // Send pageview with a custom path
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: document.title,
    });
  }, []);

  return (
    <StyledHomeWrapper>
      <div className="container">
        <div className="intro-wrapper">
          <h1>
            YOUR MAGIC. YOUR&nbsp;WAY.{" "}
            <span>Get up-to-date team news and offers.</span>
          </h1>
          <img
            className="main-image"
            src="https://cdn.nba.com/teams/uploads/sites/1610612753/2024/08/Magic-Phones-Subscription-Center.png"
            alt="Orlando Magic Notifications Screenshot"
          />
        </div>
        <div className="form-wrapper">
          <SubscriptionForm />
        </div>
      </div>
    </StyledHomeWrapper>
  );
};

export default Home;

const StyledHomeWrapper = styled.div`
  height: 100vh;
  width: 100%;
  background: #333
    url("https://cdn.nba.com/teams/uploads/sites/1610612753/2023/08/Team-Breakaway.jpg");
  background-size: cover;
  overflow: hidden;
  @media (max-width: 768px) {
    height: auto;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(20, 20, 20, 0.7);
  }

  .container {
    display: flex;
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.5);
    position: relative;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .intro-wrapper {
    width: 60%;
    height: 100vh;
    padding: 20px;
    @media (max-width: 768px) {
      height: auto;
      width: 100%;
    }
    h1 {
      color: #fff;
      font-size: 60px;
      padding: 20px 30px;
      font-weight: 900;
      letter-spacing: 0;
      text-align: center;
      line-height: 0.9em;
      @media (max-width: 768px) {
        font-size: 32px;
        padding: 20px 20px 0;
        span {
          margin-top: 5px;
        }
      }
      span {
        display: block;
        font-size: 0.5em;
        font-weight: normal;
      }
    }
    .main-image {
      /* position: relative; */
      /* bottom: 10px;
      left: -40%; */
      max-width: 90%;
      margin: 0 auto;
      display: block;
      @media (max-width: 768px) {
        /* position: relative;
        bottom: 0;
        left: -10%; */
        margin-bottom: -25%;
        max-width: 75%;
        margin: 0 auto -25%;
        display: block;
      }
    }
  }

  .form-wrapper {
    background: #fff;
    width: 100%;
    height: 100vh;
    max-width: 40%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    box-shadow: -15px 0 40px rgba(0, 0, 0, 0.5);
    overflow: auto;
    @media (max-width: 768px) {
      height: auto;
      max-width: 100%;
    }
  }
`;
