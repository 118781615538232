import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  CircularProgress,
  FormControlLabel,
  Button,
  Grid,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, postUserData } from "../actions/userDataAction";
import { useHistory, useLocation } from "react-router-dom";
import { CustomCheckbox } from "../styles/MaterialCustomStyles";
import { loadingSkeleton } from "../utils";

const Unsubscribe = () => {
  //Funtion to search for querys using Router
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  //Get email query from URL
  let query = useQuery();
  const email = query.get("EMAIL_ADDRESS");
  //Get the user state from Redux
  const stateUserData = useSelector((state) => state.user.data);
  const getUserSuccess = useSelector(
    (state) => state.user.getUserDataSuccessful
  );
  const profileUpdateSuccessful = useSelector(
    (state) => state.user.profileSuccessful
  );
  const subscriptionSuccessful = useSelector(
    (state) => state.user.subscriptionSuccessful
  );
  const unsubscribe = useSelector((state) => state.user.unsubscribe);

  //Get loading state from redux state
  const loading = useSelector((state) => state.user.loading);

  //Profile form state
  const [profileUserData, setProfileUserData] = useState({
    EMAIL_ADDRESS: email.toLowerCase(),
    ENEWS: false,
    POSTGAME_RECAP: false,
    SPECIAL_OFFERS: false,
    TICKET_OFFERS: false,
    YOUTH_CAMPS: false,
    KIDSCLUB: false,
    WINE_AUCTION: false,
    UNSUBSCRIBED: false,
    ENEWS_FREQUENCY: "",
    SMS: false,
  });

  //Get history of the page from Router
  let history = useHistory();

  const dispatch = useDispatch();

  //When the form submit button is clicked
  const handleSubmit = async (e) => {
    e.preventDefault();
    //This will disptach the action function with the user data inputted
    await dispatch(postUserData(profileUserData));
    await dispatch({ type: "PROFILE_UPDATED" });
  };

  const handleUnsubscribe = (e) => {
    e.preventDefault();
    setProfileUserData({
      ...profileUserData,
      ENEWS: false,
      POSTGAME_RECAP: false,
      SPECIAL_OFFERS: false,
      TICKET_OFFERS: false,
      YOUTH_CAMPS: false,
      KIDSCLUB: false,
      WINE_AUCTION: false,
      ENEWS_FREQUENCY: "",
      SMS: false,
      UNSUBSCRIBED: true,
    });
    dispatch({ type: "UNSUBSCRIBE" });
  };

  useEffect(() => {
    if (unsubscribe) {
      dispatch(postUserData(profileUserData));
    }
  }, [unsubscribe]);

  useEffect(() => {
    //Push url to /thankyou if postUserSuccess state is true
    if (subscriptionSuccessful)
      history.push({
        pathname: "/thankyou",
      });
  }, [subscriptionSuccessful]);

  //Get the user data
  useEffect(() => {
    dispatch(getUserData(email));
  }, []);

  //Set Profile data and State data
  useEffect(() => {
    setProfileUserData({ ...profileUserData, ...stateUserData });
  }, [getUserSuccess]);

  return (
    <StyledProfile>
      <h1>We Would Hate to See You Go</h1>
      <p>Uncheck any option below you do NOT want to receive</p>

      {getUserSuccess ? (
        <form onSubmit={handleSubmit}>
          <div className="subscriptions">
            <FormControlLabel
              control={
                <CustomCheckbox
                  id="enews"
                  name="enews"
                  className="subscription-check"
                  checked={!!profileUserData.ENEWS}
                  onChange={(e) =>
                    setProfileUserData({
                      ...profileUserData,
                      ENEWS: e.target.checked,
                      UNSUBSCRIBED: false,
                    })
                  }
                />
              }
              label="Magic News"
            />
            <FormHelperText>
              Personalized updates about the Magic, trending topics, breaking
              news and more.
            </FormHelperText>

            {profileUserData.ENEWS ? (
              <>
                <InputLabel className="main-label" id="news-frequency">
                  How often would you like Magic news emails?
                </InputLabel>
                <Select
                  labelId="news-frequency"
                  id="news-frequency"
                  value={profileUserData.ENEWS_FREQUENCY || ""}
                  variant="filled"
                  displayEmpty
                  fullWidth
                  onChange={(e) =>
                    setProfileUserData({
                      ...profileUserData,
                      ENEWS_FREQUENCY: e.target.value,
                    })
                  }>
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value={"weekly"}>Weekly</MenuItem>
                  <MenuItem value={"biweekly"}>Biweekly</MenuItem>
                  <MenuItem value={"monthly"}>Monthly</MenuItem>
                </Select>
              </>
            ) : (
              ""
            )}

            <FormControlLabel
              control={
                <CustomCheckbox
                  id="ticket-offers"
                  name="ticket-offers"
                  className="subscription-check"
                  checked={!!profileUserData.TICKET_OFFERS}
                  onChange={(e) =>
                    setProfileUserData({
                      ...profileUserData,
                      TICKET_OFFERS: e.target.checked,
                      UNSUBSCRIBED: false,
                    })
                  }
                />
              }
              label="Ticket Offers"
            />
            <FormHelperText>
              Access to ticket presales, the best deals each month and exclusive
              discounts.
            </FormHelperText>

            <FormControlLabel
              control={
                <CustomCheckbox
                  id="postgame-recap"
                  name="postgame-recap"
                  className="subscription-check"
                  checked={!!profileUserData.POSTGAME_RECAP}
                  onChange={(e) =>
                    setProfileUserData({
                      ...profileUserData,
                      POSTGAME_RECAP: e.target.checked,
                      UNSUBSCRIBED: false,
                    })
                  }
                />
              }
              label="Post-Game Recap"
            />
            <FormHelperText>
              A postgame rundown featuring video highlights, big plays, and more
              - delivered after each game.
            </FormHelperText>

            <FormControlLabel
              control={
                <CustomCheckbox
                  type="checkbox"
                  id="special-offers"
                  name="special-offers"
                  className="subscription-check"
                  checked={!!profileUserData.SPECIAL_OFFERS}
                  onChange={(e) =>
                    setProfileUserData({
                      ...profileUserData,
                      SPECIAL_OFFERS: e.target.checked,
                      UNSUBSCRIBED: false,
                    })
                  }
                />
              }
              label="Special Offers"
            />
            <FormHelperText>
              Carefully selected partner offers and sweepstakes exclusively
              available to you!
            </FormHelperText>

            {profileUserData.KIDSCLUB ? (
              <>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      type="checkbox"
                      id="kidsclub"
                      name="kidsclub"
                      className="subscription-check"
                      checked={!!profileUserData.KIDSCLUB}
                      onChange={(e) =>
                        setProfileUserData({
                          ...profileUserData,
                          KIDSCLUB: e.target.checked,
                          UNSUBSCRIBED: false,
                        })
                      }
                    />
                  }
                  label="Kids Club"
                />
                <FormHelperText>
                  Monthly news, photos and videos just for kids.
                </FormHelperText>
              </>
            ) : (
              ""
            )}

            {profileUserData.WINE_AUCTION ? (
              <>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      type="checkbox"
                      id="wine-auction"
                      name="wine-auction"
                      className="subscription-check"
                      checked={!!profileUserData.WINE_AUCTION}
                      onChange={(e) =>
                        setProfileUserData({
                          ...profileUserData,
                          WINE_AUCTION: e.target.checked,
                          UNSUBSCRIBED: false,
                        })
                      }
                    />
                  }
                  label="Orlando Wine Festival & Auction"
                />
                <FormHelperText>
                  Be the first to know about Wine Auction updates and
                  announcements.
                </FormHelperText>
              </>
            ) : (
              ""
            )}

            {/* {profileUserData.SMS ? (
              <>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      type="checkbox"
                      id="sms"
                      name="sms"
                      className="subscription-check"
                      checked={!!profileUserData.SMS}
                      onChange={(e) =>
                        setProfileUserData({
                          ...profileUserData,
                          SMS: e.target.checked,
                          UNSUBSCRIBED: false,
                        })
                      }
                    />
                  }
                  label="Text message alerts"
                />
                <FormHelperText>
                  Get exclusive news and offers straight to your phone.
                </FormHelperText>
              </>
            ) : (
              ""
            )} */}

            <FormControlLabel
              control={
                <CustomCheckbox
                  type="checkbox"
                  id="youth-camps"
                  name="youth-camps"
                  className="subscription-check"
                  checked={!!profileUserData.YOUTH_CAMPS}
                  onChange={(e) =>
                    setProfileUserData({
                      ...profileUserData,
                      YOUTH_CAMPS: e.target.checked,
                      UNSUBSCRIBED: false,
                    })
                  }
                />
              }
              label="Youth Camps, Clinics and Activities"
            />
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                className="submit"
                variant="contained"
                type="submit"
                color="primary"
                size="large"
                disabled={loading && true}>
                Update My Subscriptions
                {loading && <CircularProgress className="progress" size={24} />}
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                className="unsubscribe"
                variant="contained"
                color="primary"
                size="large"
                disabled={loading && true}
                onClick={handleUnsubscribe}>
                Unsubscribe From All
                {loading && <CircularProgress className="progress" size={24} />}
              </Button>
            </Grid>
          </Grid>

          <a
            className="policy"
            href="https://www.nba.com/privacy-policy"
            target="_blank"
            rel="noreferrer">
            View our Privacy Policy
          </a>
        </form>
      ) : !email ? (
        "Error. Please try again later"
      ) : (
        loadingSkeleton()
      )}
    </StyledProfile>
  );
};

export default Unsubscribe;

//CSS Styles for this component

const StyledProfile = styled.div`
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;
  h1 {
    margin: 0 0 10px;
  }
  .main-label {
    margin: 20px 0 5px;
  }
  input {
    display: inline-block;
  }
  button.submit {
    background: #0077c0;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    &:hover {
      background: #000;
    }
    &:disabled {
      background: #f1f1f1;
    }
  }
  button.unsubscribe {
    background: #000;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    &:hover {
      background: #666;
    }
    &:disabled {
      background: #f1f1f1;
    }
  }
  .form-field {
    margin-bottom: 20px;
  }
  .subscriptions label {
    display: block;
  }
  .subscription-check {
    font-weight: bold;
    padding: 0 8px;
  }
  .policy {
    display: block;
    text-align: center;
    color: #0077c0;
    text-decoration: none;
    margin: 25px 0 0;
  }
  .progress {
    position: absolute;
  }

  //Material UI CSS Ovewrites
  .MuiFormControlLabel-label {
    vertical-align: middle;
  }
  .MuiFormHelperText-root {
    margin-bottom: 10px;
  }
  .MuiFilledInput-root {
    margin-bottom: 30px;
  }
`;
