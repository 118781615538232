import styled from "styled-components";

const Navigation = () => {
  return (
    <StyledNav>
      <a href="https://www.nba.com/magic/" target="_blank" rel="noreferrer">
        <img
          src="https://cdn.nba.com/teams/uploads/sites/1610612753/2022/12/Orlando-Magic-Secondary-Logo.png"
          alt="Orlando Magic"
        />
      </a>
      <ul>
        <li>
          <a
            href="https://www.nba.com/magic/tickets"
            target="_blank"
            rel="noreferrer">
            Tickets
          </a>
        </li>
        <li>
          <a
            href="https://www.nba.com/magic/schedule"
            target="_blank"
            rel="noreferrer">
            Schedule
          </a>
        </li>
      </ul>
    </StyledNav>
  );
};

export default Navigation;

const StyledNav = styled.nav`
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 40px 21px -44px #000000;
  img {
    max-width: 100px;
    margin: 0 15px;
    @media (max-width: 768px) {
      max-width: 60px;
    }
  }
  ul {
    display: flex;
    list-style: none;
    align-items: center;
    margin: 0;
    padding: 0;
    li {
      display: block;
      margin: 0;
      a {
        display: block;
        font-family: "Oswald", sans-serif;
        color: white;
        padding: 25px;
        font-size: 24px;
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          background: black;
        }
        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
`;
