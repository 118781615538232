export const getRoster = () => async (dispatch) => {
  // const apiUrl =
  //   "https://content-api-prod.nba.com/public/1/leagues/nba/teams/1610612753/roster";

  // let rosterData = await fetch(apiUrl).catch((err) => {
  //   console.log(err);
  // });

  // const data = await rosterData.json();

  const data = {
    results: {
      roster: [
        {
          id: 1641783,
          name: "Tristan da Silva",
          firstName: "Tristan",
          freeAgency: {
            isEarlyTerminationOption: null,
            isPlayerOption: null,
            isTeamOption: null,
            isTwoWayFreeAgent: null,
            news: {
              articleId: null,
              articleLabel: null,
            },
            teams: {
              new: {
                id: null,
                abbr: null,
              },
              previous: {
                id: null,
                abbr: null,
              },
            },
            type: null,
          },
          lastName: "da Silva",
          displayName: "Tristan da Silva",
          number: "",
          position: "Forward",
          height: "6-8",
          weight: "217",
          age: 23,
          experience: "R",
          country: "Germany",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: null,
              ppg: null,
              rpg: null,
              apg: null,
              spg: null,
            },
            career: {
              games: null,
              ppg: null,
              rpg: null,
              apg: null,
              spg: null,
            },
          },
        },
        {
          id: 1641710,
          name: "Anthony Black",
          firstName: "Anthony",
          freeAgency: {
            isEarlyTerminationOption: null,
            isPlayerOption: null,
            isTeamOption: null,
            isTwoWayFreeAgent: null,
            news: {
              articleId: null,
              articleLabel: null,
            },
            teams: {
              new: {
                id: null,
                abbr: null,
              },
              previous: {
                id: null,
                abbr: null,
              },
            },
            type: null,
          },
          lastName: "Black",
          displayName: "Anthony Black",
          number: "0",
          position: "Guard",
          height: "6-7",
          weight: "200",
          age: 20,
          experience: "1",
          country: "USA",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 69,
              ppg: 4.6,
              rpg: 2,
              apg: 1.3,
              spg: 0.5,
            },
            career: {
              games: 69,
              ppg: 4.6,
              rpg: 2,
              apg: 1.3,
              spg: 0.5,
            },
          },
        },
        {
          id: 1628371,
          name: "Jonathan Isaac",
          firstName: "Jonathan",
          freeAgency: {
            isEarlyTerminationOption: null,
            isPlayerOption: null,
            isTeamOption: null,
            isTwoWayFreeAgent: null,
            news: {
              articleId: null,
              articleLabel: null,
            },
            teams: {
              new: {
                id: null,
                abbr: null,
              },
              previous: {
                id: null,
                abbr: null,
              },
            },
            type: null,
          },
          lastName: "Isaac",
          displayName: "Jonathan Isaac",
          number: "1",
          position: "Forward",
          height: "6-10",
          weight: "230",
          age: 26,
          experience: "5",
          country: "USA",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 58,
              ppg: 6.8,
              rpg: 4.5,
              apg: 0.5,
              spg: 0.7,
            },
            career: {
              games: 205,
              ppg: 8.4,
              rpg: 5.1,
              apg: 0.9,
              spg: 1,
            },
          },
        },
        {
          id: 202709,
          name: "Cory Joseph",
          firstName: "Cory",
          freeAgency: {
            isEarlyTerminationOption: null,
            isPlayerOption: null,
            isTeamOption: null,
            isTwoWayFreeAgent: null,
            news: {
              articleId: null,
              articleLabel: null,
            },
            teams: {
              new: {
                id: null,
                abbr: null,
              },
              previous: {
                id: null,
                abbr: null,
              },
            },
            type: null,
          },
          lastName: "Joseph",
          displayName: "Cory Joseph",
          number: "1",
          position: "Guard",
          height: "6-2",
          weight: "200",
          age: 33,
          experience: "13",
          country: "Canada",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 26,
              ppg: 2.4,
              rpg: 1.2,
              apg: 1.6,
              spg: 0.2,
            },
            career: {
              games: 816,
              ppg: 6.9,
              rpg: 2.5,
              apg: 3,
              spg: 0.7,
            },
          },
        },
        {
          id: 1631216,
          name: "Caleb Houstan",
          firstName: "Caleb",
          freeAgency: {
            isEarlyTerminationOption: null,
            isPlayerOption: null,
            isTeamOption: null,
            isTwoWayFreeAgent: null,
            news: {
              articleId: null,
              articleLabel: null,
            },
            teams: {
              new: {
                id: null,
                abbr: null,
              },
              previous: {
                id: null,
                abbr: null,
              },
            },
            type: null,
          },
          lastName: "Houstan",
          displayName: "Caleb Houstan",
          number: "2",
          position: "Guard",
          height: "6-8",
          weight: "205",
          age: 21,
          experience: "2",
          country: "Canada",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 59,
              ppg: 4.3,
              rpg: 1.4,
              apg: 0.5,
              spg: 0.3,
            },
            career: {
              games: 110,
              ppg: 4.1,
              rpg: 1.7,
              apg: 0.5,
              spg: 0.2,
            },
          },
        },
        {
          id: 1630591,
          name: "Jalen Suggs",
          firstName: "Jalen",
          freeAgency: {
            isEarlyTerminationOption: null,
            isPlayerOption: null,
            isTeamOption: null,
            isTwoWayFreeAgent: null,
            news: {
              articleId: null,
              articleLabel: null,
            },
            teams: {
              new: {
                id: null,
                abbr: null,
              },
              previous: {
                id: null,
                abbr: null,
              },
            },
            type: null,
          },
          lastName: "Suggs",
          displayName: "Jalen Suggs",
          number: "4",
          position: "Guard",
          height: "6-5",
          weight: "205",
          age: 23,
          experience: "3",
          country: "USA",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 75,
              ppg: 12.6,
              rpg: 3.1,
              apg: 2.7,
              spg: 1.4,
            },
            career: {
              games: 176,
              ppg: 11.5,
              rpg: 3.2,
              apg: 3.2,
              spg: 1.3,
            },
          },
        },
        {
          id: 1631094,
          name: "Paolo Banchero",
          firstName: "Paolo",
          freeAgency: {
            isEarlyTerminationOption: null,
            isPlayerOption: null,
            isTeamOption: null,
            isTwoWayFreeAgent: null,
            news: {
              articleId: null,
              articleLabel: null,
            },
            teams: {
              new: {
                id: null,
                abbr: null,
              },
              previous: {
                id: null,
                abbr: null,
              },
            },
            type: null,
          },
          lastName: "Banchero",
          displayName: "Paolo Banchero",
          number: "5",
          position: "Forward",
          height: "6-10",
          weight: "250",
          age: 21,
          experience: "2",
          country: "USA",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 80,
              ppg: 22.6,
              rpg: 6.9,
              apg: 5.4,
              spg: 0.9,
            },
            career: {
              games: 152,
              ppg: 21.3,
              rpg: 6.9,
              apg: 4.6,
              spg: 0.9,
            },
          },
        },
        {
          id: 203484,
          name: "Kentavious Caldwell-Pope",
          firstName: "Kentavious",
          freeAgency: {
            isEarlyTerminationOption: false,
            isPlayerOption: true,
            isTeamOption: false,
            isTwoWayFreeAgent: null,
            news: {
              articleId: 1385450,
              articleLabel: "Caldwell-Pope signs with ORL",
            },
            teams: {
              new: {
                id: 1610612753,
                abbr: null,
              },
              previous: {
                id: 1610612743,
                abbr: null,
              },
            },
            type: "ufa",
          },
          lastName: "Caldwell-Pope",
          displayName: "Kentavious Caldwell-Pope",
          number: "5",
          position: "Guard",
          height: "6-5",
          weight: "204",
          age: 31,
          experience: "11",
          country: "USA",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 76,
              ppg: 10.1,
              rpg: 2.4,
              apg: 2.4,
              spg: 1.3,
            },
            career: {
              games: 835,
              ppg: 11.4,
              rpg: 3,
              apg: 1.8,
              spg: 1.1,
            },
          },
        },
        {
          id: 1630243,
          name: "Trevelin Queen",
          firstName: "Trevelin",
          freeAgency: {
            isEarlyTerminationOption: false,
            isPlayerOption: false,
            isTeamOption: false,
            isTwoWayFreeAgent: null,
            news: {
              articleId: "",
              articleLabel: "Queen re-signs on 2-way contract",
            },
            teams: {
              new: {
                id: 1610612753,
                abbr: null,
              },
              previous: {
                id: 1610612753,
                abbr: null,
              },
            },
            type: "rfa",
          },
          lastName: "Queen",
          displayName: "Trevelin Queen",
          number: "12",
          position: "Guard",
          height: "6-5",
          weight: "190",
          age: 27,
          experience: "3",
          country: "USA",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 14,
              ppg: 2.9,
              rpg: 1.4,
              apg: 1.3,
              spg: 0.5,
            },
            career: {
              games: 31,
              ppg: 3.4,
              rpg: 1.7,
              apg: 0.9,
              spg: 0.5,
            },
          },
        },
        {
          id: 1641724,
          name: "Jett Howard",
          firstName: "Jett",
          freeAgency: {
            isEarlyTerminationOption: null,
            isPlayerOption: null,
            isTeamOption: null,
            isTwoWayFreeAgent: null,
            news: {
              articleId: null,
              articleLabel: null,
            },
            teams: {
              new: {
                id: null,
                abbr: null,
              },
              previous: {
                id: null,
                abbr: null,
              },
            },
            type: null,
          },
          lastName: "Howard",
          displayName: "Jett Howard",
          number: "13",
          position: "Guard",
          height: "6-6",
          weight: "215",
          age: 20,
          experience: "1",
          country: "USA",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 18,
              ppg: 1.6,
              rpg: 0.4,
              apg: 0.3,
              spg: 0.1,
            },
            career: {
              games: 18,
              ppg: 1.6,
              rpg: 0.4,
              apg: 0.3,
              spg: 0.1,
            },
          },
        },
        {
          id: 203914,
          name: "Gary Harris",
          firstName: "Gary",
          freeAgency: {
            isEarlyTerminationOption: false,
            isPlayerOption: false,
            isTeamOption: false,
            isTwoWayFreeAgent: null,
            news: {
              articleId: "",
              articleLabel: "Returns to ORL on 2-year deal",
            },
            teams: {
              new: {
                id: 1610612753,
                abbr: null,
              },
              previous: {
                id: 1610612753,
                abbr: null,
              },
            },
            type: "ufa",
          },
          lastName: "Harris",
          displayName: "Gary Harris",
          number: "14",
          position: "Guard",
          height: "6-4",
          weight: "210",
          age: 29,
          experience: "10",
          country: "USA",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 54,
              ppg: 6.9,
              rpg: 1.7,
              apg: 1.6,
              spg: 0.9,
            },
            career: {
              games: 570,
              ppg: 11,
              rpg: 2.4,
              apg: 1.9,
              spg: 1.1,
            },
          },
        },
        {
          id: 1629021,
          name: "Moritz Wagner",
          firstName: "Moritz",
          freeAgency: {
            isEarlyTerminationOption: false,
            isPlayerOption: false,
            isTeamOption: true,
            isTwoWayFreeAgent: null,
            news: {
              articleId: "",
              articleLabel: "Returns to ORL with new deal",
            },
            teams: {
              new: {
                id: 1610612753,
                abbr: null,
              },
              previous: {
                id: 1610612753,
                abbr: null,
              },
            },
            type: "ufa",
          },
          lastName: "Wagner",
          displayName: "Moritz Wagner",
          number: "21",
          position: "Forward-Center",
          height: "6-11",
          weight: "245",
          age: 27,
          experience: "6",
          country: "Germany",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 80,
              ppg: 10.9,
              rpg: 4.3,
              apg: 1.2,
              spg: 0.5,
            },
            career: {
              games: 333,
              ppg: 8.8,
              rpg: 3.9,
              apg: 1.2,
              spg: 0.5,
            },
          },
        },
        {
          id: 1630532,
          name: "Franz Wagner",
          firstName: "Franz",
          freeAgency: {
            isEarlyTerminationOption: null,
            isPlayerOption: null,
            isTeamOption: null,
            isTwoWayFreeAgent: null,
            news: {
              articleId: null,
              articleLabel: null,
            },
            teams: {
              new: {
                id: null,
                abbr: null,
              },
              previous: {
                id: null,
                abbr: null,
              },
            },
            type: null,
          },
          lastName: "Wagner",
          displayName: "Franz Wagner",
          number: "22",
          position: "Forward",
          height: "6-10",
          weight: "220",
          age: 22,
          experience: "3",
          country: "Germany",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 72,
              ppg: 19.7,
              rpg: 5.3,
              apg: 3.7,
              spg: 1.1,
            },
            career: {
              games: 231,
              ppg: 17.8,
              rpg: 4.6,
              apg: 3.4,
              spg: 1,
            },
          },
        },
        {
          id: 1628976,
          name: "Wendell Carter Jr.",
          firstName: "Wendell",
          freeAgency: {
            isEarlyTerminationOption: null,
            isPlayerOption: null,
            isTeamOption: null,
            isTwoWayFreeAgent: null,
            news: {
              articleId: null,
              articleLabel: null,
            },
            teams: {
              new: {
                id: null,
                abbr: null,
              },
              previous: {
                id: null,
                abbr: null,
              },
            },
            type: null,
          },
          lastName: "Carter Jr.",
          displayName: "Wendell Carter Jr.",
          number: "34",
          position: "Center-Forward",
          height: "6-10",
          weight: "270",
          age: 25,
          experience: "6",
          country: "USA",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 55,
              ppg: 11,
              rpg: 6.9,
              apg: 1.7,
              spg: 0.6,
            },
            career: {
              games: 315,
              ppg: 12.5,
              rpg: 8.5,
              apg: 2,
              spg: 0.6,
            },
          },
        },
        {
          id: 1629048,
          name: "Goga Bitadze",
          firstName: "Goga",
          freeAgency: {
            isEarlyTerminationOption: false,
            isPlayerOption: false,
            isTeamOption: false,
            isTwoWayFreeAgent: null,
            news: {
              articleId: "",
              articleLabel: "Returns to ORL",
            },
            teams: {
              new: {
                id: 1610612753,
                abbr: null,
              },
              previous: {
                id: 1610612753,
                abbr: null,
              },
            },
            type: "ufa",
          },
          lastName: "Bitadze",
          displayName: "Goga Bitadze",
          number: "35",
          position: "Center-Forward",
          height: "6-10",
          weight: "250",
          age: 25,
          experience: "5",
          country: "Georgia",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 62,
              ppg: 5,
              rpg: 4.6,
              apg: 1.3,
              spg: 0.5,
            },
            career: {
              games: 249,
              ppg: 4.9,
              rpg: 3.4,
              apg: 0.9,
              spg: 0.4,
            },
          },
        },
        {
          id: 1630175,
          name: "Cole Anthony",
          firstName: "Cole",
          freeAgency: {
            isEarlyTerminationOption: null,
            isPlayerOption: null,
            isTeamOption: null,
            isTwoWayFreeAgent: null,
            news: {
              articleId: null,
              articleLabel: null,
            },
            teams: {
              new: {
                id: null,
                abbr: null,
              },
              previous: {
                id: null,
                abbr: null,
              },
            },
            type: null,
          },
          lastName: "Anthony",
          displayName: "Cole Anthony",
          number: "50",
          position: "Guard",
          height: "6-2",
          weight: "185",
          age: 24,
          experience: "4",
          country: "USA",
          merchUrl: "",
          teamId: 1610612753,
          stats: {
            season: {
              games: 81,
              ppg: 11.6,
              rpg: 3.8,
              apg: 2.9,
              spg: 0.8,
            },
            career: {
              games: 253,
              ppg: 13.4,
              rpg: 4.6,
              apg: 4.1,
              spg: 0.7,
            },
          },
        },
      ],
      coaches: [
        {
          id: 202427,
          displayName: "Jamahl Mosley",
          firstName: "Jamahl",
          lastName: "Mosley",
          type: "Head Coach",
        },
        {
          id: 2763,
          displayName: "Lionel Chalmers",
          firstName: "Lionel",
          lastName: "Chalmers",
          type: "Assistant Coach",
        },
        {
          id: 203170,
          displayName: "Dale Osbourne",
          firstName: "Dale",
          lastName: "Osbourne",
          type: "Assistant Coach",
        },
        {
          id: 203596,
          displayName: "Bret Brielmaier",
          firstName: "Bret",
          lastName: "Brielmaier",
          type: "Assistant Coach",
        },
        {
          id: 203605,
          displayName: "Jesse Mermuys",
          firstName: "Jesse",
          lastName: "Mermuys",
          type: "Assistant Coach",
        },
        {
          id: 2720,
          displayName: "Ernest Eugene",
          firstName: "Ernest",
          lastName: "Eugene",
          type: "Trainer",
        },
      ],
    },
  };

  const playerArray = data.results.roster;

  //sort players alphabetically
  playerArray.sort(function (a, b) {
    var nameA = a.firstName.toLowerCase(),
      nameB = b.firstName.toLowerCase();
    if (nameA < nameB)
      //sort string ascending
      return -1;
    if (nameA > nameB) return 1;
    return 0; //default return value (no sorting)
  });

  dispatch({
    type: "GET_ROSTER",
    payload: playerArray,
  });
};
