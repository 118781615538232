export const getEmailQuery = (email) => async (dispatch) => {
    dispatch({
        type: "EMAIL_URL_QUERY",
        payload: email
    })
}


//function to get user data

export const getUserData = (email) => async (dispatch) => {

    //Get data if email query is in the url
    if(email){    

        //Disptach loading Redux
        dispatch({type: "DATA_LOADING"})

        //Get data from API

        const api_url = `/.netlify/functions/getsub?email=${email.toLowerCase()}`;
        const response = await fetch(api_url);
        const userData = await response.json();

        await dispatch({
            type: "GET_USER_DATA",
            payload: userData[0]
        })
        
    } 

}

//function to post user data
export const postUserData = (inputtedData) => async (dispatch) => {


    dispatch({type: "DATA_LOADING"})

    const api_url = `/.netlify/functions/postsub`;
    await fetch(api_url, {
        method: 'POST',
        body: JSON.stringify(inputtedData),
        headers: {
            "Content-Type": "application/json"
        }
    });

    await dispatch({
        type: "POST_SUBSCRIPTION_DATA",
        payload: inputtedData
    })
    
}


