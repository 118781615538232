const initialState = {
    data: {},
    roster: [],
    loading: false,
}

const userDataReducer = (state = initialState, action) => {
    switch(action.type){
        case "GET_USER_DATA":
            return {...state, data: action.payload, loading: false, getUserDataSuccessful: true}
        case "POST_SUBSCRIPTION_DATA":
            return {...state, data: action.payload, loading: false, subscriptionSuccessful: true}
        case "PROFILE_UPDATED":
            return {...state, data: action.payload, loading: false, profileSuccessful: true}
        case "DATA_LOADING":
            return {...state, loading: true}
        case "GET_ROSTER":
            return {...state, roster: action.payload}
        case "UNSUBSCRIBE":
            return {...state, unsubscribe: true}
        default:
            return {...state}
    }
}

export default userDataReducer;