/*
 *** My Loyal Blue Subcription Home Page ***
 */

import KidsSubscriptionForm from "../components/WineAuctionForm";
import styled from "styled-components";

const WineAuction = () => {
  return (
    <StyledHomeWrapper>
      <div className="container">
        <div className="intro-wrapper">
          <h1>Orlando Wine Festival & Auction</h1>
          <h2>
            Receive the latest updates on the event and be the first to know
            when packages become available.
          </h2>
          <img
            className="main-image"
            src="https://wineauctionorlando.com/wp-content/uploads/2019/03/wine_auction-382.jpg"
            alt="Email Screenshot"
          />
        </div>
        <div className="form-wrapper">
          <KidsSubscriptionForm />
        </div>
      </div>
    </StyledHomeWrapper>
  );
};

export default WineAuction;

const StyledHomeWrapper = styled.div`
  height: 100vh;
  width: 100%;
  background: #333
    url("https://wineauctionorlando.com/wp-content/uploads/2018/12/bg-4-1650x662.jpg");
  background-size: cover;
  background-position: center;
  overflow: hidden;
  @media (max-width: 768px) {
    height: auto;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(51, 51, 51, 0.8);
  }

  .container {
    display: flex;
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.5);
    position: relative;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .intro-wrapper {
    width: 60%;
    height: 100vh;
    padding: 20px 30px;
    @media (max-width: 768px) {
      height: auto;
      width: 100%;
    }
    h1 {
      color: #fff;
      font-size: 40px;
      font-weight: normal;
      margin-bottom: 15px;
      @media (max-width: 768px) {
        font-size: 32px;
      }
    }
    h2 {
      color: #fff;
      font-weight: normal;
    }
    p.subhealine {
      font-size: 18px;
      color: #fff;
      &:after {
        content: "";
        width: 100px;
        height: 6px;
        background: #0077c0;
        display: block;
        margin-top: 10px;
      }
    }
    ul {
      padding: 0 25px;
      li {
        color: #fff;
        font-size: 18px;
        margin: 10px 0;
      }
    }
    .main-image {
      max-width: 1000px;
      position: relative;
      top: 25px;
      left: -30%;
      @media (max-width: 768px) {
        position: relative;
        bottom: 0;
        left: -10%;
        margin-bottom: -25%;
        max-width: 100%;
      }
    }
  }

  .form-wrapper {
    background: #fff;
    width: 100%;
    height: 100vh;
    max-width: 40%;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    box-shadow: -15px 0 40px rgba(0, 0, 0, 0.5);
    overflow: auto;
    @media (max-width: 768px) {
      height: auto;
      max-width: 100%;
    }
  }
`;
