import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { postUserData } from "../actions/userDataAction";
import { useHistory, useLocation } from "react-router-dom";
import { maskNumbers } from "../utils";
//Material UI
import {
  CircularProgress,
  TextField,
  FormControlLabel,
  Button,
  FormHelperText,
} from "@mui/material";
import Icon from "@mui/material/Icon";

import {
  CustomCheckbox,
  CustomTextField,
} from "../styles/MaterialCustomStyles";

//Funtion to search for querys using Router
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SubscriptionForm = () => {
  //Get querys from URL
  let query = useQuery();
  const SOURCE = query.get("source");
  const EVENT = query.get("event");

  //Subscription form state
  const [userData, setUserData] = useState({
    EMAIL_ADDRESS: "",
    POSTAL_CODE: "",
    PHONE: "",
    SOURCE,
    EVENT,
    ENEWS: false,
    POSTGAME_RECAP: false,
    SPECIAL_OFFERS: false,
    TICKET_OFFERS: false,
    KIDSCLUB: false,
    UNSUBSCRIBED: false,
    SMS: false,
    FIRST_NAME: "",
    LAST_NAME: "",
  });

  //Get loading state from redux state
  const loading = useSelector((state) => state.user.loading);
  //Get subscriptionSuccessful state from redux state
  const success = useSelector((state) => state.user.subscriptionSuccessful);

  const dispatch = useDispatch();

  //When the form submit button is clicked
  const handleSubmit = (e) => {
    e.preventDefault();
    //This will disptach the action function with the user data inputted
    dispatch(postUserData(userData));
  };

  //Get history of the page from Router
  let history = useHistory();

  useEffect(() => {
    //Push url to /thankyou if success state is true and coming from an event
    if (success && userData.EVENT)
      history.push({
        pathname: "/thankyou",
        search: `?EMAIL_ADDRESS=${userData.EMAIL_ADDRESS}&event=${userData.EVENT}`,
        state: userData,
      });
  });

  useEffect(() => {
    //Push url to /profile if success state is true
    if (success && !userData.EVENT)
      history.push({
        pathname: "/profile",
        search: `?EMAIL_ADDRESS=${userData.EMAIL_ADDRESS}&new=true`,
        state: userData,
      });
  });

  return (
    <StyledForm>
      <StyledHeadline>
        <img
          src="https://cdn.nba.com/teams/uploads/sites/1610612753/2022/12/Orlando-Magic-Secondary-Logo.png"
          alt="Orlando Magic Logo"
        />
        <h1>
          Orlando Magic
          <span>Subscriptions</span>
        </h1>
      </StyledHeadline>

      <form onSubmit={handleSubmit}>
        {userData.SOURCE === "gr" || userData.SOURCE === "grassroots" ? (
          <>
            <TextField
              id="first_name"
              type="text"
              label="First Name"
              name="first_name"
              fullWidth
              className="form-field"
              value={userData.FIRST_NAME}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  FIRST_NAME: e.target.value,
                })
              }
              variant="filled"
              required
            />
            <TextField
              id="last_name"
              type="text"
              label="Last Name"
              name="last_name"
              fullWidth
              className="form-field"
              value={userData.LAST_NAME}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  LAST_NAME: e.target.value,
                })
              }
              variant="filled"
              required
            />
          </>
        ) : (
          ""
        )}

        <CustomTextField
          id="email_address"
          type="email"
          label="Email Address"
          name="email_address"
          fullWidth
          className="form-field"
          value={userData.EMAIL_ADDRESS}
          onChange={(e) =>
            setUserData({ ...userData, EMAIL_ADDRESS: e.target.value })
          }
          variant="filled"
          required
        />

        <TextField
          id="postal_code"
          type="text"
          label="Postal Code"
          name="postal_code"
          fullWidth
          className="form-field"
          value={userData.POSTAL_CODE}
          onChange={(e) =>
            setUserData({
              ...userData,
              POSTAL_CODE: maskNumbers(e.target.value),
            })
          }
          variant="filled"
          required
        />

        <TextField
          id="phone"
          type="text"
          label="Phone Number"
          name="phone"
          fullWidth
          className="form-field"
          value={userData.PHONE}
          onChange={(e) =>
            setUserData({ ...userData, PHONE: maskNumbers(e.target.value) })
          }
          variant="filled"
          required
        />

        {/* <h2>Text Subscriptions</h2>

        <FormControlLabel
          control={
            <CustomCheckbox
              id="sms"
              name="sms"
              className="subscription-check"
              checked={!!userData.SMS}
              onChange={(e) =>
                setUserData({ ...userData, SMS: e.target.checked })
              }
            />
          }
          label="Text Alerts"
        />
        <FormHelperText>
          Get team news, exclusive promotions, and personalized content straight
          to your phone.*
        </FormHelperText> */}

        <h2>Email Subscriptions</h2>

        <FormControlLabel
          control={
            <CustomCheckbox
              id="ticket-offers"
              name="ticket-offers"
              className="subscription-check"
              checked={!!userData.TICKET_OFFERS}
              onChange={(e) =>
                setUserData({ ...userData, TICKET_OFFERS: e.target.checked })
              }
            />
          }
          label="Ticket Offers"
        />
        <FormHelperText>
          Access to ticket presales, the best deals each month and exclusive
          discounts.
        </FormHelperText>

        <FormControlLabel
          control={
            <CustomCheckbox
              id="enews"
              name="enews"
              className="subscription-check"
              checked={!!userData.ENEWS}
              onChange={(e) =>
                setUserData({ ...userData, ENEWS: e.target.checked })
              }
            />
          }
          label="Magic News"
        />
        <FormHelperText>
          Monthly updates about the Magic, trending topics, breaking news and
          more.
        </FormHelperText>

        <FormControlLabel
          control={
            <CustomCheckbox
              id="postgame-recap"
              name="postgame-recap"
              className="subscription-check"
              checked={!!userData.POSTGAME_RECAP}
              onChange={(e) =>
                setUserData({ ...userData, POSTGAME_RECAP: e.target.checked })
              }
            />
          }
          label="Postgame Recap"
        />
        <FormHelperText>
          A postgame rundown featuring video highlights, big plays, and more -
          delivered after each game.
        </FormHelperText>

        <FormControlLabel
          control={
            <CustomCheckbox
              id="special-offers"
              name="special-offers"
              className="subscription-check"
              checked={!!userData.SPECIAL_OFFERS}
              onChange={(e) =>
                setUserData({ ...userData, SPECIAL_OFFERS: e.target.checked })
              }
            />
          }
          label="Special Offers"
        />
        <FormHelperText>
          Carefully selected partner offers and sweepstakes exclusively
          available to you!
        </FormHelperText>

        <FormControlLabel
          control={
            <CustomCheckbox
              id="kidsclub"
              name="kidsclub"
              className="subscription-check"
              checked={!!userData.KIDSCLUB}
              onChange={(e) =>
                setUserData({ ...userData, KIDSCLUB: e.target.checked })
              }
            />
          }
          label="Kids Club"
        />
        <FormHelperText>
          Get monthly news, photos, videos, Team Shop discounts, exclusive
          events and more just for kids.
        </FormHelperText>

        <Button
          className="submit"
          variant="contained"
          type="submit"
          color="primary"
          size="large"
          endIcon={<Icon>send</Icon>}
          disabled={loading && true}>
          Sign Me Up
          {loading && <CircularProgress className="progress" size={24} />}
        </Button>

        <p>
          View our{" "}
          <a
            className="policy"
            href="https://www.nba.com/privacy-policy"
            target="_blank"
            rel="noreferrer">
            Terms
          </a>
          {" & "}
          <a
            className="policy"
            href="https://www.nba.com/privacy-policy"
            target="_blank"
            rel="noreferrer">
            Privacy Policy
          </a>
        </p>

        <p
          style={{
            color: "#757575",
            fontSize: "0.75rem",
            letterSpacing: "0.03333em",
            lineHeight: "1.6",
          }}>
          By entering your phone number and submitting this form, you agree to
          receive recurring automated marketing messages and personalized
          updates from the Orlando Magic at the cell number provided. Consent is
          not a condition of purchase. Reply HELP for help and STOP to cancel.
          Msg frequency varies. Msg and data rates may apply.
        </p>
      </form>
    </StyledForm>
  );
};

export default SubscriptionForm;

//CSS Styles for this component

const StyledHeadline = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  margin-bottom: 15px;
  img {
    max-width: 100px;
    margin: 0 10px;
  }
  h1 {
    font-weight: 300;
    line-height: 1em;
    font-size: 30px;
    span {
      display: block;
      font-size: 0.75em;
    }
  }
`;

const StyledForm = styled.div`
  width: 100%;
  max-width: 330px;
  padding: 15px;
  position: relative;
  margin: auto 0;
  h2 {
    font-weight: normal;
    &:after {
      content: "";
      width: 100px;
      height: 6px;
      background: #0077c0;
      display: block;
      margin-top: 10px;
    }
  }

  button.submit {
    background: #0077c0;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    &:hover {
      background: #000;
    }
    &:disabled {
      background: #f1f1f1;
    }
  }
  label {
    display: inline-block;
    margin-right: 5px;
  }
  input[type="checkbox"]:checked {
    color: black;
  }
  .subscription-check {
    font-weight: bold;
    padding: 0 8px;
  }
  .progress {
    position: absolute;
  }
  .form-field {
    margin-bottom: 20px;
    div &:after {
      display: none;
    }
  }
  .policy {
    text-align: center;
    color: #0077c0;
    text-decoration: none;
    margin: 25px 0 0;
  }

  //Material UI CSS Ovewrites
  .MuiFormControlLabel-label {
    vertical-align: middle;
  }
  .MuiFormHelperText-root {
    margin-bottom: 10px;
  }
`;
