import styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Typography from "@mui/material/Typography";

const ThankYou = () => {
  const unsubscribe = useSelector((state) => state.user.unsubscribe);

  //Funtion to search for querys using Router
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const EVENT = query.get("event");
  // console.log(EVENT);

  return (
    <StyledThankYou>
      {EVENT ? (
        <>
          <h1>Thank You For Signing Up!</h1>
          <p>
            You may adjust your subscriptions or update your profile at the
            bottom of any email you receive from us. If you experience any
            problems or have feedback, please email us at{" "}
            <a href="mailto:emailsupport@orlandomagic.com">
              emailsupport@orlandomagic.com
            </a>
            .
          </p>
        </>
      ) : !unsubscribe ? (
        <>
          <h1>Thank You! Your Updates Have Been Made</h1>
          <p>
            You may adjust your subscriptions or update your profile at the
            bottom of any email you receive from us. If you experience any
            problems or have feedback, please email us at{" "}
            <a href="mailto:emailsupport@orlandomagic.com">
              emailsupport@orlandomagic.com
            </a>
            .
          </p>
        </>
      ) : (
        <>
          <h1>You will be missed</h1>
          <p>
            You may adjust your subscriptions or update your profile at the
            bottom of any email you receive from us. If you experience any
            problems or have feedback, please email us at{" "}
            <a href="mailto:emailsupport@orlandomagic.com">
              emailsupport@orlandomagic.com
            </a>
            .
          </p>
          <h2>Stay in touch</h2>
          <div className="social">
            <a
              href="https://www.facebook.com/OrlandoMagic"
              target="_blank"
              rel="noopener noreferrer">
              <FacebookIcon fontSize="large" style={{ color: "#4267B2" }} />
              <Typography variant="srOnly">Facebook</Typography>
            </a>

            <a
              href="https://twitter.com/orlandomagic"
              target="_blank"
              rel="noopener noreferrer">
              <TwitterIcon fontSize="large" style={{ color: "#1DA1F2" }} />
              <Typography variant="srOnly">Twitter</Typography>
            </a>

            <a
              href="https://www.instagram.com/orlandomagic/"
              target="_blank"
              rel="noopener noreferrer">
              <InstagramIcon fontSize="large" style={{ color: "#833AB4" }} />
              <Typography variant="srOnly">Instagram</Typography>
            </a>

            <a
              href="https://www.youtube.com/orlandomagic"
              target="_blank"
              rel="noopener noreferrer">
              <YouTubeIcon fontSize="large" style={{ color: "#FF0000" }} />
              <Typography variant="srOnly">Youtube</Typography>
            </a>
          </div>
        </>
      )}
    </StyledThankYou>
  );
};

export default ThankYou;

const StyledThankYou = styled.div`
  max-width: 800px;
  margin: 25px auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  h1 {
    margin: 0 0 10px;
  }
  p {
    font-size: 18px;
  }
  .social a {
    color: #0077c0;
    margin-right: 15px;
    &:hover {
      color: #000;
      cursor: pointer;
    }
  }
`;
