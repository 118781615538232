import { MenuItem } from "@mui/material";
import { Skeleton } from '@mui/material';

//function to populate numbers for Select Component
export const populateNumbersSelect = (limit) => {
  let arr = [];
  for (let i = 1; i <= limit; i++) {
    arr.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }
  return arr;
};

//Month Names
export const monthsArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//Opponents
export const opponents = [
  "Atlanta Hawks",
  "Boston Celtics",
  "Brooklyn Nets",
  "Charlotte Hornets",
  "Chicago Bulls",
  "Cleveland Cavaliers",
  "Dallas Mavericks",
  "Denver Nuggets",
  "Detroit Pistons",
  "Golden State Warriors",
  "Houston Rockets",
  "Indiana Pacers",
  "Los Angeles Clippers",
  "Los Angeles Lakers",
  "Memphis Grizzlies",
  "Miami Heat",
  "Milwaukee Bucks",
  "Minnesota Timberwolves",
  "New Orleans Pelicans",
  "New York Knicks",
  "Oklahoma City Thunder",
  "Philadelphia 76ers",
  "Phoenix Suns",
  "Portland Trail Blazers",
  "Sacramento Kings",
  "San Antonio Spurs",
  "Toronto Raptors",
  "Utah Jazz",
  "Washington Wizards",
];

export const loadingSkeleton = () => {
  return <>
    <Skeleton
      className="bone"
      style={{ marginBottom: "25px", borderRadius: "6px" }}
      variant="rectangular"
      height={60}
      animation="wave"
    />
    <Skeleton
      className="bone"
      style={{ marginBottom: "25px", borderRadius: "6px" }}
      variant="rectangular"
      height={60}
      animation="wave"
    />
    <Skeleton
      className="bone"
      style={{ marginBottom: "25px", borderRadius: "6px" }}
      variant="rectangular"
      height={80}
      animation="wave"
    />
    <Skeleton
      className="bone"
      style={{ marginBottom: "25px", borderRadius: "6px" }}
      variant="rectangular"
      height={60}
      animation="wave"
    />
    <Skeleton
      className="bone"
      style={{ marginBottom: "25px", borderRadius: "6px" }}
      variant="rectangular"
      height={60}
      animation="wave"
    />
    <Skeleton
      className="bone"
      style={{ marginBottom: "25px", borderRadius: "6px" }}
      variant="rectangular"
      height={60}
      animation="wave"
    />
    <Skeleton
      className="bone"
      style={{ marginBottom: "25px", borderRadius: "6px" }}
      variant="rectangular"
      height={80}
      animation="wave"
    />
  </>;
};

export const loadingSkeletonSmall = () => {
  return <>
    <Skeleton
      className="bone"
      style={{ marginBottom: "25px", borderRadius: "6px" }}
      variant="rectangular"
      height={60}
      animation="wave"
    />
    <Skeleton
      className="bone"
      style={{ marginBottom: "25px", borderRadius: "6px" }}
      variant="rectangular"
      height={60}
      animation="wave"
    />
  </>;
};

export const maskNumbers = (value) => {
  const maskedValue = value.replace(/\D+/g, "");
  return maskedValue;
};
