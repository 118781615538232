import withStyles from '@mui/styles/withStyles';
import { Checkbox, TextField } from '@mui/material';


const checkBoxStyles = theme => ({
    root: {
      '&$checked': {
        color: '#0077c0',
      },
    },
    checked: {},
   })

const textFieldStyles = () => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'orange',
        color: 'orange'
      }
  }
}
});

export const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
export const CustomTextField = withStyles(textFieldStyles)(TextField);