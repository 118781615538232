import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, postUserData } from "../actions/userDataAction";
import { useLocation } from "react-router-dom";
import { loadingSkeletonSmall } from "../utils";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";

import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

const SubscribeToParam = () => {
  const dispatch = useDispatch();

  //Funtion to search for querys using Router
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  //Get email query from URL
  let query = useQuery();
  const email = query.get("EMAIL_ADDRESS");
  const POSTGAME_RECAP = query.get("POSTGAME_RECAP");
  const ENEWS = query.get("ENEWS");
  const SPECIAL_OFFERS = query.get("SPECIAL_OFFERS");
  const TICKET_OFFERS = query.get("TICKET_OFFERS");
  const KIDSCLUB = query.get("KIDSCLUB");
  const WINE_AUCTION = query.get("WINE_AUCTION");
  // const SMS = query.get("SMS");

  const queries = {
    POSTGAME_RECAP,
    ENEWS,
    SPECIAL_OFFERS,
    TICKET_OFFERS,
    KIDSCLUB,
    WINE_AUCTION,
  };

  const trueQueries = Object.keys(queries)
    .filter((key) => queries[key] === "true")
    .map((key) =>
      key.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      })
    );

  const trueQueriesString = trueQueries.join(", ");

  //Profile state
  const [profileUserData, setProfileUserData] = useState({
    ...Object.keys(queries)
      .filter((key) => queries[key])
      .reduce((acc, key) => ({ ...acc, [key]: true }), {}),
    EMAIL_ADDRESS: email.toLowerCase(),
  });

  const [readyToDispatch, setReadyToDispatch] = useState(false);

  const [alertVisible, setAlertVisible] = useState(true);

  //Get the user state from Redux
  const stateUserData = useSelector((state) => state.user.data);

  const getUserSuccess = useSelector(
    (state) => state.user.getUserDataSuccessful
  );

  const subscriptionSuccessful = useSelector(
    (state) => state.user.subscriptionSuccessful
  );

  const handleUpdate = async () => {
    await dispatch(postUserData(profileUserData));
    await dispatch({ type: "POST_SUBSCRIPTION_DATA" });
  };

  //Get the user data
  useEffect(() => {
    dispatch(getUserData(email));
  }, []);

  //Set Profile data and State data
  useEffect(() => {
    if (Object.keys(stateUserData).length > 0) {
      //Merge the state data with the profile data
      const mergedUserData = { ...profileUserData };
      Object.keys(stateUserData).forEach((key) => {
        if (!mergedUserData.hasOwnProperty(key)) {
          mergedUserData[key] = stateUserData[key];
        }
      });

      setProfileUserData({
        ...stateUserData,
        ...mergedUserData,
        UNSUBSCRIBED: false,
      });
      setReadyToDispatch(true);
    }
  }, [getUserSuccess]);

  //Dispatch the updated profile data
  useEffect(() => {
    if (readyToDispatch) {
      handleUpdate();
    }
  }, [readyToDispatch]);

  return (
    <StyledProfile>
      {subscriptionSuccessful && trueQueries.length > 0 ? (
        <>
          <h1>Your Subscriptions Have Been&nbsp;Updated</h1>
          <Box sx={{ width: "100%" }}>
            <Collapse in={alertVisible}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertVisible(false);
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}>
                You will now receive {trueQueriesString}
              </Alert>
            </Collapse>
          </Box>
        </>
      ) : !email ? (
        <>
          <h1>Error</h1>
          <p>Please try again later</p>
        </>
      ) : trueQueries.length === 0 ? (
        <>
          <h1>Error: no queries</h1>
          <p>Please try again later</p>
        </>
      ) : (
        loadingSkeletonSmall()
      )}
    </StyledProfile>
  );
};

export default SubscribeToParam;

//CSS Styles for this component

const StyledProfile = styled.div`
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;
  h1 {
    margin: 0 0 10px;
    text-align: center;
  }
  .main-label {
    margin: 20px 0 5px;
  }
  input {
    display: inline-block;
  }
  button.submit {
    background: #0077c0;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    &:hover {
      background: #000;
    }
    &:disabled {
      background: #f1f1f1;
    }
  }
  button.unsubscribe {
    background: #000;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    &:hover {
      background: #666;
    }
    &:disabled {
      background: #f1f1f1;
    }
  }
  .form-field {
    margin-bottom: 20px;
  }
  .subscriptions label {
    display: block;
  }
  .subscription-check {
    font-weight: bold;
    padding: 0 8px;
  }
  .policy {
    display: block;
    text-align: center;
    color: #0077c0;
    text-decoration: none;
    margin: 25px 0 0;
  }
  .progress {
    position: absolute;
  }

  //Material UI CSS Ovewrites
  .MuiFormControlLabel-label {
    vertical-align: middle;
  }
  .MuiFormHelperText-root {
    margin-bottom: 10px;
  }
  .MuiFilledInput-root {
    margin-bottom: 30px;
  }
`;
