import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
  populateNumbersSelect,
  monthsArray,
  loadingSkeleton,
  opponents,
} from "../utils";
import {
  CircularProgress,
  TextField,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  FormHelperText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, postUserData } from "../actions/userDataAction";
import { getRoster } from "../actions/rosterAction";
import { useHistory, useLocation } from "react-router-dom";
import { CustomCheckbox } from "../styles/MaterialCustomStyles";
import { maskNumbers } from "../utils";

const Profile = () => {
  //Funtion to search for querys using Router
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  //Get email query from URL
  let query = useQuery();
  const email = query.get("EMAIL_ADDRESS");
  const editProfileQuery = query.get("edit");
  const newProfileQuery = query.get("new");
  const kidsClubQuery = query.get("kids");

  //Get the user state from Redux
  const stateUserData = useSelector((state) => state.user.data);
  const getUserSuccess = useSelector(
    (state) => state.user.getUserDataSuccessful
  );
  const profileUpdateSuccessful = useSelector(
    (state) => state.user.profileSuccessful
  );
  const players = useSelector((state) => state.user.roster);
  //Get loading state from redux state
  const loading = useSelector((state) => state.user.loading);

  //Profile form state
  const [profileUserData, setProfileUserData] = useState({
    EMAIL_ADDRESS: "",
    POSTAL_CODE: "",
    ADDRESS: "",
    CITY: "",
    PHONE4: "",
    ADULTS: "",
    KIDS: "",
    BIRTHDAY_DAY: "",
    BIRTHDAY_MONTH: "",
    FAVORITE_PLAYER: "",
    FAVORITEOPPONENT1: "",
    FAVORITEOPPONENT2: "",
    FAVORITEOPPONENT3: "",
    ENEWS: false,
    ENEWS_FREQUENCY: "",
    SMS: false,
    POSTGAME_RECAP: false,
    SPECIAL_OFFERS: false,
    TICKET_OFFERS: false,
    YOUTH_CAMPS: false,
    UNSUBSCRIBED: false,
  });

  const [phoneValue, setPhoneValue] = React.useState({
    phone: "",
    showPhone: false,
  });

  const getPlayerId = () => {
    players.forEach((player) => {
      const playerName = player.name;
      if (playerName === profileUserData.FAVORITE_PLAYER) {
        setProfileUserData({
          ...profileUserData,
          FAVORITE_PLAYER_ID: player.id,
        });
      }
    });
  };

  const handleClickShowPassword = () => {
    setPhoneValue({ ...phoneValue, showPhone: !phoneValue.showPhone });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //Get history of the page from Router
  let history = useHistory();

  const dispatch = useDispatch();

  //When the form submit button is clicked
  const handleSubmit = async (e) => {
    e.preventDefault();
    //This will disptach the action function with the user data inputted
    await dispatch(postUserData(profileUserData));
    await dispatch({ type: "PROFILE_UPDATED" });
  };

  useEffect(() => {
    //Push url to /thankyou if postUserSuccess state is true
    if (profileUpdateSuccessful)
      history.push({
        pathname: "/thankyou",
        search: `?EMAIL_ADDRESS=${profileUserData.EMAIL_ADDRESS}`,
      });
  }, [profileUpdateSuccessful]);

  //Get the user data
  useEffect(() => {
    if (editProfileQuery) {
      dispatch(getUserData(email));
    }
  }, [editProfileQuery]);

  //Set Profile data and State data
  useEffect(() => {
    setProfileUserData({ ...profileUserData, ...stateUserData });
  }, [stateUserData]);

  //Get Roster
  useEffect(() => {
    dispatch(getRoster());
  }, []);

  //Update Player ID
  useEffect(() => {
    getPlayerId();
  }, [profileUserData.FAVORITE_PLAYER]);

  return (
    <StyledProfile>
      {newProfileQuery ? (
        <>
          <h1>
            Thank You! <span>You Are Now Subscribed</span>
          </h1>
          <p className="copy">
            Customize your experience even more, if you would like!
          </p>
        </>
      ) : (
        <h1>Update Your Profile</h1>
      )}

      {profileUserData.FAVORITE_PLAYER_ID ? (
        <div className="container">
          <img
            className="favplayer"
            onError={(e) => {
              e.target.removeAttribute("src");
              e.target.removeAttribute("alt");
            }}
            src={`https://ak-static.cms.nba.com/wp-content/uploads/silos/nba/latest/440x700/${profileUserData.FAVORITE_PLAYER_ID}.png`}
            alt={profileUserData.FAVORITE_PLAYER}
          />
        </div>
      ) : (
        ""
      )}

      {profileUserData.EMAIL_ADDRESS ? (
        <form onSubmit={handleSubmit}>
          {!newProfileQuery ? (
            <>
              <TextField
                type="text"
                label="Postal Code"
                name="postal_code"
                id="postal_code"
                variant="filled"
                className="form-field"
                value={profileUserData.POSTAL_CODE}
                onChange={(e) =>
                  setProfileUserData({
                    ...profileUserData,
                    POSTAL_CODE: e.target.value,
                  })
                }
                fullWidth
                required
              />

              <TextField
                type="text"
                label="Phone"
                name="phone"
                id="phone"
                variant="filled"
                className="form-field"
                value={
                  profileUserData.PHONE4
                    ? "••••••" + profileUserData.PHONE4
                    : ""
                }
                fullWidth
                disabled
                helperText="To adjust your phone number, please email us at emailsupport@orlandomagic.com"
              />

              <div className="subscriptions">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      id="enews"
                      name="enews"
                      className="subscription-check"
                      checked={!!profileUserData.ENEWS}
                      onChange={(e) => {
                        setProfileUserData({
                          ...profileUserData,
                          ENEWS: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Magic News"
                />
                <FormHelperText>
                  Personalized updates about the Magic, trending topics,
                  breaking news and more.
                </FormHelperText>

                <FormControlLabel
                  control={
                    <CustomCheckbox
                      id="ticket-offers"
                      name="ticket-offers"
                      className="subscription-check"
                      checked={!!profileUserData.TICKET_OFFERS}
                      onChange={(e) =>
                        setProfileUserData({
                          ...profileUserData,
                          TICKET_OFFERS: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Ticket Offers"
                />
                <FormHelperText>
                  Access to ticket presales, the best deals each month and
                  exclusive discounts.
                </FormHelperText>

                <FormControlLabel
                  control={
                    <CustomCheckbox
                      id="postgame-recap"
                      name="postgame-recap"
                      className="subscription-check"
                      checked={!!profileUserData.POSTGAME_RECAP}
                      onChange={(e) =>
                        setProfileUserData({
                          ...profileUserData,
                          POSTGAME_RECAP: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Postgame Recap"
                />
                <FormHelperText>
                  A postgame rundown featuring video highlights, big plays, and
                  more - delivered after each game.
                </FormHelperText>

                <FormControlLabel
                  control={
                    <CustomCheckbox
                      type="checkbox"
                      id="special-offers"
                      name="special-offers"
                      className="subscription-check"
                      checked={!!profileUserData.SPECIAL_OFFERS}
                      onChange={(e) =>
                        setProfileUserData({
                          ...profileUserData,
                          SPECIAL_OFFERS: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Special Offers"
                />
                <FormHelperText>
                  Carefully selected partner offers and sweepstakes exclusively
                  available to you!
                </FormHelperText>
              </div>
            </>
          ) : (
            ""
          )}
          <InputLabel className="main-label" id="favorite-player">
            Who’s your favorite player?
          </InputLabel>
          <Select
            labelId="favorite-player"
            id="favorite-player-select"
            name="favorite-player"
            value={profileUserData.FAVORITE_PLAYER || ""}
            variant="filled"
            displayEmpty
            fullWidth
            onChange={(e) => {
              setProfileUserData({
                ...profileUserData,
                FAVORITE_PLAYER: e.target.value,
              });
            }}>
            <MenuItem value="" disabled>
              Select Player
            </MenuItem>
            {players.map((player) => {
              return (
                <MenuItem
                  key={player.id}
                  value={player.name}
                  data-id={player.id}>
                  <img
                    width="50px"
                    className="player-headshot"
                    src={`https://cdn.nba.com/headshots/nba/latest/1040x760/${player.id}.png`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        "https://cdn.nba.com/teams/uploads/sites/1610612753/2022/12/default-player.png";
                    }}
                    alt={player.name}
                  />
                  {player.name}
                </MenuItem>
              );
            })}
          </Select>
          <InputLabel className="main-label" id="opponents">
            Your favorite opponents
          </InputLabel>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Select
                labelId="opponent_one"
                id="opponent_one"
                value={profileUserData.FAVORITEOPPONENT1 || ""}
                variant="filled"
                displayEmpty
                fullWidth
                onChange={(e) =>
                  setProfileUserData({
                    ...profileUserData,
                    FAVORITEOPPONENT1: e.target.value,
                  })
                }>
                <MenuItem value="" disabled>
                  Opponent One
                </MenuItem>
                {opponents
                  .filter(
                    (opponent) =>
                      opponent !== profileUserData.FAVORITEOPPONENT2 &&
                      opponent !== profileUserData.FAVORITEOPPONENT3
                  )
                  .map((opponent) => (
                    <MenuItem key={opponent} value={opponent}>
                      {opponent}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Select
                labelId="opponent_two"
                id="opponent_two"
                value={profileUserData.FAVORITEOPPONENT2 || ""}
                variant="filled"
                displayEmpty
                fullWidth
                onChange={(e) =>
                  setProfileUserData({
                    ...profileUserData,
                    FAVORITEOPPONENT2: e.target.value,
                  })
                }>
                <MenuItem value="" disabled>
                  Opponent Two
                </MenuItem>
                {opponents
                  .filter(
                    (opponent) =>
                      opponent !== profileUserData.FAVORITEOPPONENT1 &&
                      opponent !== profileUserData.FAVORITEOPPONENT3
                  )
                  .map((opponent) => (
                    <MenuItem key={opponent} value={opponent}>
                      {opponent}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Select
                labelId="opponent_three"
                id="opponent_three"
                value={profileUserData.FAVORITEOPPONENT3 || ""}
                variant="filled"
                displayEmpty
                fullWidth
                onChange={(e) =>
                  setProfileUserData({
                    ...profileUserData,
                    FAVORITEOPPONENT3: e.target.value,
                  })
                }>
                <MenuItem value="" disabled>
                  Opponent Three
                </MenuItem>
                {opponents
                  .filter(
                    (opponent) =>
                      opponent !== profileUserData.FAVORITEOPPONENT1 &&
                      opponent !== profileUserData.FAVORITEOPPONENT2
                  )
                  .map((opponent) => (
                    <MenuItem key={opponent} value={opponent}>
                      {opponent}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
          <InputLabel className="main-label" id="birthday">
            When's your birthday?
          </InputLabel>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Select
                labelId="birthday"
                id="birthday-month"
                value={profileUserData.BIRTHDAY_MONTH || ""}
                variant="filled"
                displayEmpty
                fullWidth
                onChange={(e) =>
                  setProfileUserData({
                    ...profileUserData,
                    BIRTHDAY_MONTH: e.target.value,
                  })
                }>
                <MenuItem value="" disabled>
                  Month
                </MenuItem>
                {monthsArray.map((month) => (
                  <MenuItem key={month} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Select
                labelId="birthday"
                id="birthday-day"
                value={profileUserData.BIRTHDAY_DAY || ""}
                variant="filled"
                displayEmpty
                fullWidth
                onChange={(e) =>
                  setProfileUserData({
                    ...profileUserData,
                    BIRTHDAY_DAY: e.target.value,
                  })
                }>
                <MenuItem value="" disabled>
                  Day
                </MenuItem>
                {populateNumbersSelect(31)}
              </Select>
            </Grid>
          </Grid>
          <KidsClubField>
            {/* If KidsClub query is true, show this fields */}
            {kidsClubQuery ? (
              <>
                <TextField
                  id="kids"
                  type="text"
                  label="Number of Kids in Family"
                  name="kids"
                  fullWidth
                  className="form-field"
                  value={profileUserData.KIDS}
                  onChange={(e) =>
                    setProfileUserData({
                      ...profileUserData,
                      KIDS: maskNumbers(e.target.value),
                    })
                  }
                  variant="filled"
                />
                <TextField
                  id="address"
                  type="text"
                  label="Address"
                  name="address"
                  fullWidth
                  className="form-field"
                  value={profileUserData.ADDRESS}
                  onChange={(e) =>
                    setProfileUserData({
                      ...profileUserData,
                      ADDRESS: e.target.value,
                    })
                  }
                  variant="filled"
                />
                <TextField
                  id="city"
                  type="text"
                  label="City"
                  name="city"
                  fullWidth
                  className="form-field"
                  value={profileUserData.CITY}
                  onChange={(e) =>
                    setProfileUserData({
                      ...profileUserData,
                      CITY: e.target.value,
                    })
                  }
                  variant="filled"
                />
                <TextField
                  id="postal_code"
                  type="text"
                  label="Postal Code"
                  name="postal_code"
                  fullWidth
                  className="form-field"
                  value={profileUserData.POSTAL_CODE}
                  onChange={(e) =>
                    setProfileUserData({
                      ...profileUserData,
                      POSTAL_CODE: maskNumbers(e.target.value),
                    })
                  }
                  variant="filled"
                />
              </>
            ) : (
              ""
            )}
          </KidsClubField>
          <CommunicationPreferences>
            {profileUserData.ENEWS ? (
              <>
                <h2>Communication Preferences</h2>
                <InputLabel className="main-label" id="news-frequency">
                  How often would you like Magic news emails?
                </InputLabel>
                <Select
                  labelId="news-frequency"
                  id="news-frequency"
                  value={profileUserData.ENEWS_FREQUENCY || ""}
                  variant="filled"
                  displayEmpty
                  fullWidth
                  onChange={(e) =>
                    setProfileUserData({
                      ...profileUserData,
                      ENEWS_FREQUENCY: e.target.value,
                    })
                  }>
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value={"weekly"}>Weekly</MenuItem>
                  <MenuItem value={"biweekly"}>Biweekly</MenuItem>
                  <MenuItem value={"monthly"}>Monthly</MenuItem>
                </Select>
              </>
            ) : (
              ""
            )}

            {/* {profileUserData.PHONE ? (
              <>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      type="checkbox"
                      id="youth-camps"
                      name="youth-camps"
                      checked={!!profileUserData.SMS}
                      onChange={(e) =>
                        setProfileUserData({
                          ...profileUserData,
                          SMS: e.target.checked,
                        })
                      }
                    />
                  }
                  label="I want to receive team news and promotions via text message."
                />
              </>
            ) : (
              ""
            )} */}
          </CommunicationPreferences>

          <ExtraSubcriptions>
            <h2>Other Interests</h2>

            <FormControlLabel
              control={
                <CustomCheckbox
                  type="checkbox"
                  id="youth-camps"
                  name="youth-camps"
                  checked={!!profileUserData.YOUTH_CAMPS}
                  onChange={(e) =>
                    setProfileUserData({
                      ...profileUserData,
                      YOUTH_CAMPS: e.target.checked,
                    })
                  }
                />
              }
              label="Youth Camps, Clinics and Activities"
            />
          </ExtraSubcriptions>
          <Button
            className="submit"
            variant="contained"
            type="submit"
            color="primary"
            size="large"
            disabled={loading && true}>
            Submit
            {loading && <CircularProgress className="progress" size={24} />}
          </Button>
        </form>
      ) : editProfileQuery ? (
        loadingSkeleton()
      ) : (
        "Error. Please try again."
      )}
    </StyledProfile>
  );
};

export default Profile;

//CSS Styles for this component

const StyledProfile = styled.div`
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;
  h1 {
    margin: 35px 0 25px;
  }
  h1 span {
    font-weight: normal;
  }
  p.copy {
    font-size: 1.2em;
    margin-bottom: 35px;
  }
  .main-label {
    margin: 20px 0 5px;
  }
  input {
    display: inline-block;
  }
  button.submit {
    background: #0077c0;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    &:hover {
      background: #000;
    }
    &:disabled {
      background: #f1f1f1;
    }
  }
  .progress {
    position: absolute;
  }
  .form-field {
    margin-bottom: 20px;
  }
  .subscriptions label {
    display: block;
  }
  .subscription-check {
    font-weight: bold;
    padding: 0 8px;
  }
  .container {
    max-width: 800px;
    position: relative;
  }
  .favplayer {
    position: absolute;
    right: -40%;
    opacity: 0.1;
    top: 0;
    pointer-events: none;
    @media (max-width: 768px) {
      opacity: 0.05;
    }
  }

  //Material UI CSS Ovewrites
  .MuiFormControlLabel-label {
    vertical-align: middle;
  }
  .MuiFormHelperText-root {
    margin-bottom: 10px;
  }
`;
const ExtraSubcriptions = styled.div`
  margin: 35px 0 0;
  h2 {
    margin-bottom: 0;
  }
`;

const CommunicationPreferences = styled.div`
  margin: 35px 0 0;
  h2 {
    margin-bottom: 0;
  }
`;

const KidsClubField = styled.div`
  margin: 20px 0 0;
  h2 {
    margin-bottom: 0;
  }
`;
